<template>
  <div>
    <new-to-do-sidebar />
    <to-do-details-sidebar />
    <button v-b-toggle.sidebar-info-to-do @click="search" class="btn-todo">
      <img
        src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/logo_oraculo_01.svg"
        alt="futuro-logo"
        height="35"
      />
      <b class="text-white mb-0">TO-DO</b>
    </button>

    <div class="w-75">
      <b-sidebar
        id="sidebar-info-to-do"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
      >
        <button
          v-b-toggle.sidebar-info-to-do
          @click="search"
          class="btn-todo btn-inside"
        >
          <img
            src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/logo_oraculo_01.svg"
            alt="futuro-logo"
            height="35"
          />
          <b class="text-white mb-0">TO-DO</b>
        </button>
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4 class="mb-0">Tarefas / To-do</h4>
          <feather-icon
            v-b-toggle.sidebar-info-to-do
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
          />
        </div>

        <b-col cols="12" class="px-0">
          <div :class="['box-datecard p-1', `bg-date-${skin}`]">
            <div v-if="currentMonth && yearOfWeek" class="mb-1">
              <span class="text-weight">{{
                currentMonth + " " + yearOfWeek
              }}</span>
            </div>

            <b-nav class="grid-container align-items-center mb-0" pills>
              <button :class="['side-btn', `side-${skin}`]" @click="prevWeek">
                &#10094;
              </button>
              <div class="grid-day">
                <b-nav-item
                  class="nav-btn"
                  v-for="(day, index) in daysOfWeek"
                  :key="index"
                  :active="index === activeDayIndex"
                  @click="selectDay(index)"
                >
                  <div class="d-flex flex-column align-items-center">
                    <h4 class="text-weight">{{ day.date }}</h4>
                    <span> {{ day.abbreviatedDay }}</span>
                  </div>
                </b-nav-item>
              </div>
              <button :class="['side-btn', `side-${skin}`]" @click="nextWeek">
                &#10095;
              </button>
            </b-nav>
          </div>
        </b-col>
        <b-col cols="12" class="px-0">
          <section class="container-schedule">
            <div
              v-if="loading.todo"
              class="h-100 d-flex align-items-center justify-content-center p-2"
            >
              <b-spinner variant="primary" />
            </div>
            <div
              v-if="!items.length && !loading.todo"
              class="h-100 d-flex align-items-center justify-content-center p-2"
            >
              <span class="text-center">Não existem tarefas para este dia</span>
            </div>
            <div v-if="items.length && !loading.todo">
              <div
                :class="[
                  'box-schedule h-100',
                  items.length > 7 ? 'scrolled' : '',
                  `scroll-${skin}`,
                ]"
              >
                <div v-for="(todo, index) in items" :key="index">
                  <article
                    v-if="!todo.is_completed"
                    class="d-flex align-items-center border-bottom p-2 cursor-pointer"
                  >
                    <div class="media-left mr-50">
                      <div class="user-action">
                        <b-form-checkbox
                          :checked="checkIsTrue(todo.is_completed)"
                          :id="`check-task-${todo.id}`"
                          @change="checkTask(todo.id)"
                          title="Marcar como concluída"
                        />
                      </div>
                    </div>
                    <aside
                      @click="openDetails(todo.id)"
                      class="d-flex justify-content-between align-items-center cursor-pointer w-100"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <div class="main-box w-100">
                          <div class="title mb-0">
                            <h5 :title="todo.title" class="mb-0">
                              {{ todo.title }}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div class="schedule-time" v-if="!todo.is_full_day">
                        {{ todo.task_hour }}
                      </div>
                    </aside>
                  </article>
                </div>
                <div v-if="totalItems > 10" class="m-2">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted"
                        >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
                        {{ totalItems }}</span
                      >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        :value="currentPage"
                        :total-rows="totalItems"
                        :per-page="itemsPerPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @change="search"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </section>
        </b-col>
        <div class="d-flex mt-2 px-2 justify-content-between">
          <b-button
            variant="primary"
            type="submit"
            class="to-do-buttons w-100"
            @click="triggerOpenNewToDoSidebar"
            >+ Adicionar nova tarefa</b-button
          >
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import {
  BSidebar,
  BRow,
  BCol,
  BNav,
  BNavItem,
  BPagination,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import * as types from "../store/types";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useAppConfig from "@core/app-config/useAppConfig";
import ToDoDetailsSidebar from "../components/ToDoDetailsSidebar.vue";
import NewToDoSidebar from "../components/NewToDoSidebar.vue";

export default {
  components: {
    BRow,
    BCol,
    BSidebar,
    BNav,
    BNavItem,
    BPagination,
    NewToDoSidebar,
    ToDoDetailsSidebar,
  },
  data() {
    return {
      daysOfWeek: [],
      activeDayIndex: null,
      activeDate: null,
      weekOffset: 0,
      visi: true,
      loading: {
        todo: false,
      },
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), v$: useVuelidate(), skin };
  },

  computed: {
    ...mapGetters({
      todos: types.TODOS_FLOAT_SIDEBAR,
    }),
    items: function () {
      return this.todos?.data || [];
    },
    totalItems: function () {
      return this.todos?.total || 0;
    },
    pageItemsFrom: function () {
      return this.todos?.from || 0;
    },
    pageItemsTo: function () {
      return this.todos?.to || 0;
    },

    currentMonth() {
      const monthIndex = new Date(
        this.daysOfWeek[this.activeDayIndex]?.fullDate
      ).getMonth();
      const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      return monthNames[monthIndex];
    },

    yearOfWeek() {
      const yearIndex = new Date(
        this.daysOfWeek[this.activeDayIndex]?.fullDate
      ).getFullYear();

      return yearIndex;
    },

    activeDateInFullYear() {
      return this.daysOfWeek[this.activeDayIndex]?.fullDate
        .toISOString()
        .slice(0, 10);
    },
  },

  watch: {
    activeDate: function (value) {
      this.search(1);
    },
  },
  mounted() {
    this.getDaysOfWeek();
    this.search();
  },
  methods: {
    ...mapActions({
      searchTodos: types.GET_TODOS_FLOAT_SIDEBAR,
      openNewToDoSidebar: types.OPEN_NEW_TO_DO_SIDEBAR,
      getTasksFromToday: types.GET_TASKS_FROM_TODAY,
      markAsCompleted: types.MARK_AS_COMPLETED,
      deleteTask: types.DELETE_TASK,
      markAsImportant: types.MARK_AS_IMPORTANT,
      openToDoDetailsSidebar: types.OPEN_TO_DO_DETAILS_SIDEBAR,
    }),

    search(currentPage) {
      this.loading.todo = true;
      this.currentPage = Number.isInteger(currentPage)
        ? currentPage
        : this.currentPage;
      this.searchTodos({
        date_start: this.activeDateInFullYear,
        currentPage: this.currentPage,
        limit: this.itemsPerPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as tarefas. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.getTasksFromToday()
            .finally(() => {
              this.loading.todo = false;
            });
        });
    },

    checkTask(taskId) {
      this.markAsCompleted(taskId).then(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Sucesso",
            text: "Tarefa concluída com sucesso!",
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
        this.search();
      });
    },
    openDetails(taskId) {
      this.openToDoDetailsSidebar({
        id: taskId,
        saveAction: this.search,
      });
    },
    triggerOpenNewToDoSidebar() {
      this.openNewToDoSidebar({
        id: undefined,
        saveAction: this.search,
        visible: true,
      });
    },

    checkIsTrue(value) {
      return value === 1 ? true : false;
    },

    getDaysOfWeek() {
      const today = new Date();
      today.setDate(today.getDate() + this.weekOffset * 7);
      const daysOfWeek = [];

      const currentDayIndex = today.getDay();

      for (let i = 0; i < 7; i++) {
        const day = new Date(today);
        day.setDate(today.getDate() + (i - currentDayIndex));
        daysOfWeek.push({
          date: day.getDate(),
          fullDate: day,
          abbreviatedDay: day.toLocaleDateString("pt-BR", { weekday: "short" }),
        });
      }

      this.activeDayIndex = currentDayIndex;

      this.daysOfWeek = daysOfWeek;

      this.activeDate = this.daysOfWeek[currentDayIndex].date;
    },

    selectDay(index) {
      this.activeDayIndex = index;

      this.activeDate = this.daysOfWeek[index].date;
    },

    nextWeek() {
      this.weekOffset++;
      this.getDaysOfWeek();
    },

    prevWeek() {
      this.weekOffset--;
      this.getDaysOfWeek();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/views/dashboard/components.scss";

.sidebar-xg {
  width: 36rem;
}

.to-do-buttons {
  width: 10rem;
}

.nav-btn > .nav-link[data-v-2ebf092b] {
  padding: 0.5rem !important;
}

.btn-inside {
  left: -50px !important;
  right: auto !important;
}

.btn-todo {
  position: fixed;
  padding: 0.5rem;
  z-index: calc(1029 + 5);
  top: 136px;
  right: 0;
  background-color: #8900ff;
  border: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: grid;
  justify-items: center;

  b {
    margin-top: 0.15rem;
    font-size: 0.75em;
  }
}

.b-sidebar-outer {
  z-index: calc(1029 + 5) !important;
}

.container-schedule[data-v-2ebf092b] {
  height: 408px;
}

@media (max-width: 578px) {
  .grid-day {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
