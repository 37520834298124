<template>
  <b-nav-item @click="openWhatsApp">
    <b-button
      id="users-help"
      class="btn-icon"
      variant="flat"
      style="padding: 0"
    >
      <feather-icon size="21" icon="HelpCircleIcon" />
    </b-button>
    <b-tooltip
      target="users-help"
      title="Fale com o Suporte T.I"
      placement="top"
      boundary="viewport"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem, BButton, BTooltip } from "bootstrap-vue";
import * as sharedTypes from "@/modules/shared/store/types";
import { mapActions } from "vuex";

export default {
  components: {
    BNavItem,
    BButton,
    BTooltip,
  },
  methods: {
    ...mapActions({
      getWhatsappSupport: sharedTypes.GET_BUSINESS_CONFIG_WHATSAPP_SUPPORT,
    }),
    openWhatsApp() {
      this.getWhatsappSupport().then((resp) => {
        setTimeout(() => {
          window.open(resp.data.value).focus();
        });
      });
    },
  },
};
</script>
