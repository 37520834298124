import { READ_ACTION, CONTRACT_RESOURCE, FUTURO_STORE } from "@/constants/resources";

export default [
  {
    title: "Loja Futuro",
    icon: "none",
    children: [
      {
        title: "Acessar loja Futuro",
        href: "https://futuroconsultoria.lojavirtualnuvem.com.br/",
        icon: "ShoppingCartIcon",
        resource: FUTURO_STORE,
        action: READ_ACTION,
      }
    ],
  },
];
