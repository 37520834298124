<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template v-if="userData" #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar size="40" variant="light-primary" class="badge-minimal">
        <img
          v-if="userData.avatar_url"
          :src="userData.avatar_url"
          height="45"
        />
        <feather-icon v-else icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{
        name: 'consultant-details-summary',
        params: { id: userData ? userData.appify_consultant_id : undefined },
      }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Minha conta</span>
    </b-dropdown-item>

    <!--
    <b-dropdown-item
      :to="{ name: 'contract-save' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Configurações</span>
    </b-dropdown-item>
    -->

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Sair</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { avatarText } from "@core/utils/filter";
import * as types from "@/modules/account/store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    };
  },
  setup() {
    const { skin } = useAppConfig();

    return { skin };
  },
  computed: {
    ...mapGetters({
      userData: types.USER,
    }),
  },
  methods: {
    ...mapActions({
      logoutUser: types.LOGOUT,
    }),
    logout() {
      this.logoutUser()
        .then((data) => this.$router.push({ name: "account-login" }))
        .catch((error) => {
          this.$swal({
            title: "Ooops!",
            text: "Ocorreu um erro ao tentar desconectar seu usuário. Tente novamente mais tarde.",
            icon: "error",
            background: `${this.skin === "dark" ? "#283046" : ""}`,
            customClass: {
              confirmButton: "btn btn-primary",
              htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>
