import {
  UPDATE_ACTION,
  FINANCIAL_SOLUTION_RESOURCE,
  PARTNER_COMPANY_RESOURCE,
  PRODUCT_RESOURCE,
} from "@/constants/resources";

export default [
  // {
  //     title: 'Soluções financeiras',
  //     route: 'financial-solutions-list',
  //     icon: 'CheckCircleIcon',
  //     resource: FINANCIAL_SOLUTION_RESOURCE,
  //     action: UPDATE_ACTION,
  // },
  // {
  //     title: 'Empresas',
  //     route: 'companies-list',
  //     icon: 'HomeIcon',
  //     resource: PARTNER_COMPANY_RESOURCE,
  //     action: UPDATE_ACTION,
  // },
  // {
  //     title: 'Produtos',
  //     route: 'products-list',
  //     icon: 'BoxIcon',
  //     resource: PRODUCT_RESOURCE,
  //     action: UPDATE_ACTION,
  // },
];
