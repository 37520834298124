/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/s

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import report from "./report";
import contract from "./contract";
import financial_solution from "./financial_solution";
import notifications from "./notifications";
import dashboard from "./dashboard";
import departments from "./departments";
import kpi from "./kpi";
import consultant from "./consultant";
import customer from "./customer";
import financial from "./financial";
import system_settings from "./system_settings";
import education from "./education";
import franchises from "./franchises";
import futuro_store from "./futuro_store";
import nps from "./nps";

// Array of sections
export default [
  ...dashboard,
  ...customer,
  ...contract,
  ...financial_solution,
  ...notifications,
  ...kpi,
  ...financial,
  ...consultant,
  ...education,
  ...report,
  ...franchises,
  ...nps,
  ...system_settings,
  ...departments,
  ...futuro_store
];
