<template>
  <div>
    <b-sidebar
      id="sidebar-withdrawal-details"
      sidebar-class="sidebar-lg"
      :visible="toDoDetailsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateToDoDetailsSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Sua tarefa</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="m-2" v-if="todo">
          <b-row>
            <b-col cols="12" class="mb-1">
              <h5>{{ todo.title }}</h5>
            </b-col>
          </b-row>
          <b-row class="border-top">
            <b-col cols="3" class="mb-1 mt-1"> Dia </b-col>
            <b-col cols="9" class="mb-1 mt-1">
              {{ todo.date_start | todoDateToLiteralFormat("long") }}
            </b-col>
            <template v-if="!todo.is_full_day">
              <b-col cols="3" class="mb-1"> Horário: </b-col>
              <b-col cols="9" class="mb-1">
                {{ todo.date_start | onlyTime }}
              </b-col>
            </template>
            <b-col cols="3" class="mb-1"> Importante: </b-col>
            <b-col cols="9" class="mb-1">
              <div v-if="todo.is_important">
                <feather-icon
                  icon="StarIcon"
                  size="17"
                  class="text-warning fill-current"
                />
                Sim
              </div>
              <div v-else>Não</div>
            </b-col>
            <b-col cols="3" class="mb-1"> Recorrente: </b-col>
            <b-col cols="9" class="mb-1">
              <div v-if="todo.is_recurring">Sim</div>
              <div v-else>Não</div>
            </b-col>

            <b-col cols="3" class="mb-1" v-if="todo.is_recurring">
              Repetir a cada:
            </b-col>
            <b-col cols="9" class="mb-1"
              ><div
                v-for="(type, index) in repeatTypeChoice"
                :key="index"
                v-if="todo.is_recurring"
              >
                <span>{{ todo.recurrence_quantity || 0 }} {{ type }}</span>
              </div>
            </b-col>

            <b-col
              cols="3"
              class="mb-1"
              v-if="todo.is_recurring && todo.recurrence_type == 'week'"
            >
              Repetir:
            </b-col>
            <b-col
              cols="9"
              class="mb-1"
              v-if="todo.is_recurring && todo.recurrence_type == 'week'"
              ><div v-for="(days, index) in daysSelected" :key="index">
                <span>{{ days }}</span>
              </div>
            </b-col>
            <b-col
              cols="3"
              class="mb-1"
              v-if="todo.is_recurring && todo.recurrence_ends_at"
            >
              Até:
            </b-col>
            <b-col
              cols="9"
              class="mb-1"
              v-if="todo.is_recurring && todo.recurrence_ends_at"
            >
              <span>{{ formatDate(todo.recurrence_ends_at) }} </span>
            </b-col>
            <b-col
              cols="3"
              class="mb-1"
              v-if="todo.is_recurring && todo.recurrence_ends_after"
            >
              Após:
            </b-col>
            <b-col
              cols="9"
              class="mb-1"
              v-if="todo.is_recurring && todo.recurrence_ends_after"
            >
              <span
                >{{ todo.recurrence_ends_after || 0 }}
                {{
                  todo.recurrence_ends_after > 1 ? "ocorrências" : "ocorrência"
                }}</span
              >
            </b-col>
          </b-row>
          <div class="d-flex mt-5 justify-content-between">
            <b-button
              :disabled="saving"
              variant="outline-primary"
              class="to-do-details-buttons"
              @click="hide"
            >
              Voltar
            </b-button>
            <b-button
              v-if="!todo.is_completed"
              :disabled="saving"
              variant="primary"
              type="submit"
              class="to-do-details-buttons"
              @click="checkTask"
            >
              {{ saving ? "Aguarde..." : "Concluir" }}
            </b-button>
          </div>
        </div>
        <div v-else class="d-flex justify-content-center m-2">
          <b-spinner variant="primary" />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import * as types from "../store/types";
import moment from "moment";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      todo: undefined,
      saving: false,
      days: {
        monday: "segunda-feira",
        tuesday: "terça-feira",
        wednesday: "quarta-feira",
        thursday: "quinta-feira",
        friday: "sexta-feira",
        saturday: "sábado",
        sunday: "domingo",
      },
      repeatTypeList: {
        day: "dia(s)",
        week: "semana(s)",
        month: "mes(es)s",
        year: "ano(s)",
      },
      daysOfWeek: [],
      repeatType: [],
    };
  },
  setup() {
    return { toast: useToast() };
  },
  computed: {
    ...mapGetters({
      toDoDetailsSidebar: types.TO_DO_DETAILS_SIDEBAR,
    }),

    daysSelected() {
      this.daysOfWeek = this.todo.selected_days_of_week;

      return this.daysOfWeek.map((day) => this.days[day]);
    },

    repeatTypeChoice() {
      this.repeatType = [this.todo.recurrence_type];

      return this.repeatType.map((type) => this.repeatTypeList[type]);
    },
  },
  methods: {
    ...mapMutations({
      mutateToDoDetailsSidebar: types.MUTATE_TO_DO_DETAILS_SIDEBAR,
    }),
    ...mapActions({
      getTask: types.GET_TASK,
      markAsCompleted: types.MARK_AS_COMPLETED,
    }),
    formatDate() {
      let date = this.todo.recurrence_ends_at;
      return moment(date).format("DD/MM/YYYY");
    },
    onShow() {
      this.getTask(this.toDoDetailsSidebar.id)
        .then((response) => {
          if (response.data.id) {
            this.todo = response.data;
          } else {
            throw new DOMException("Tarefa não encontrada.");
          }
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os detalhes da tarefa. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.mutateToDoDetailsSidebar({ visible: false });
          this.clear();
        });
    },
    checkTask() {
      this.saving = true;
      this.markAsCompleted(this.toDoDetailsSidebar.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A tarefa foi finalizada com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateToDoDetailsSidebar({ visible: false });
          this.toDoDetailsSidebar.saveAction();
          this.clear();
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao finalizar a tarefa. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    clear() {
      this.todo = undefined;
    },
  },
};
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
.to-do-details-buttons {
  width: 10rem;
}
</style>
