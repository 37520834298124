<template>
  <b-nav-item @click="toggle">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavItem } from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapActions, mapGetters } from 'vuex'
import { USER, SAVE_DARK_MODE } from '@/modules/account/store/types'

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig()
    const toast = useToast() 
    return { skin, toast }
  },
  computed: {
    ...mapGetters({
      user: USER
    }),
    isDark: function(){
      return this.user?.dark_mode_admin || false
    }
  },
  mounted() {
    this.skin = this.isDark ? 'dark' : 'light'
  },
  methods: {
    ...mapActions({
      saveDarkMode: SAVE_DARK_MODE
    }),
    toggle(){
      this.user.dark_mode_admin = this.isDark ? 0 : 1
      this.skin = this.isDark ? 'dark' : 'light'
      this.saveDarkMode({
        dark_mode_admin: this.user?.dark_mode_admin || false
      })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar a preferência do sistema. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
    }
  }
}
</script>
