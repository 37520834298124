<template>
  <div>
    <notification-sidebar />
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
      @shown="onShow"
      @hide="onHide"
      @hidden="loading = false"
    >
      <template #button-content>
        <feather-icon
          :badge="counterTotal"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>
      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
          <b-badge v-if="counterNotifications > 0" pill variant="light-primary">
            <span> {{ counterNotifications }} novas </span>
          </b-badge>
          &nbsp;
          <b-badge v-if="counterPendencies > 0" pill variant="light-danger">
            <span> {{ counterPendencies }} pendências </span>
          </b-badge>
          &nbsp;
          <b-badge v-if="counterSolved > 0" pill variant="light-success">
            <span> {{ counterSolved }} resolvidas </span>
          </b-badge>
        </div>
      </li>
      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
        @ps-y-reach-end="endScroll"
      >
        <template v-if="items.length > 0">
          <div v-for="item in items" :key="item.id">
            <b-media>
              <b-button
                variant="link"
                :style="
                  item.read
                    ? 'padding:0; color:#6e6b7b !important'
                    : 'padding:0'
                "
                class="text-left"
                @click.prevent="read(item)"
              >
                <p class="media-heading" style="padding-bottom: 5px">
                  <span class="font-weight-bolder">
                    <b-badge variant="purple">
                      {{ item.notification_type.type }}
                    </b-badge>
                    <b-badge
                      v-if="item.notification_tag"
                      variant="blue"
                      class="ml-1"
                    >
                      {{ item.notification_tag.tag }}
                    </b-badge>
                  </span>
                </p>
                <p class="media-heading" style="padding-bottom: 5px">
                  <small class="text-muted mr-1">
                    {{ handleDate(item.created_at) }}
                  </small>
                </p>
                <span
                  v-html="
                    truncateString(
                      getNotificationMessage(
                        item.metadata,
                        item.type,
                        item.tag_id
                      ),
                      115
                    )
                  "
                ></span>
              </b-button>
            </b-media>
          </div>
        </template>
        <template v-else-if="!loading">
          <span class="d-flex justify-content-center m-2">
            Nenhuma nova notificação!</span
          >
        </template>
      </vue-perfect-scrollbar>
      <div v-if="loading" class="d-flex justify-content-center m-2">
        <b-spinner variant="primary" />
      </div>
      <!-- Cart Footer -->
      <li class="dropdown-menu-footer">
        <div class="justify-content-center notification-cart-footer">
          <b-dropdown-item v-if="canReadAll">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="readAll"
            >
              Marcar todas
            </b-button>
          </b-dropdown-item>
          <b-dropdown-item>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              @click="goToNotificationInbox"
            >
              Caixa de entrada
            </b-button>
          </b-dropdown-item>
        </div>
      </li>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BButton,
  BSpinner,
  BIconFileEaselFill,
  BDropdownItem,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { mapMutations, mapActions, mapGetters } from "vuex";
import { truncateString } from "@/helpers/converters";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";
import NotificationSidebar from "./NotificationSidebar";
import {
  APP_BACKOFFICE_ROLE_ID,
  APP_FINANCIAL_ROLE_ID,
  APP_ADMIN_ROLE_ID,
  APP_MASTER_ROLE_ID,
} from "@/constants/auth";
import useAppConfig from "@core/app-config/useAppConfig";
import getNotificationMessage from "@/libs/notification";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
    NotificationSidebar,
    BSpinner,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      reading: false,
      visible: false,
      currentPage: 1,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      timer: null,
    };
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  computed: {
    ...mapGetters({
      notifications: types.NOTIFICATIONS,
      notificationSibebar: types.NOTIFICATION_SIDEBAR,
      user: accountTypes.USER,
      notificationsCounter: types.NOTIFICATIONS_COUNTER,
    }),
    items: function () {
      return this.notifications?.data || [];
    },
    total: function () {
      return this.notifications?.total || 0;
    },
    counterNotifications: function () {
      return this.notificationsCounter?.notifications || 0;
    },
    counterPendencies: function () {
      return this.notificationsCounter?.pendencies || 0;
    },
    counterSolved: function () {
      return this.notificationsCounter?.solved || 0;
    },
    counterTotal: function () {
      return (
        this.counterNotifications + this.counterPendencies + this.counterSolved
      );
    },
    canReadAll: function () {
      return (
        this.user?.user_role_id != APP_BACKOFFICE_ROLE_ID &&
        this.user?.user_role_id != APP_FINANCIAL_ROLE_ID &&
        this.user?.user_role_id != APP_ADMIN_ROLE_ID &&
        this.user?.user_role_id != APP_MASTER_ROLE_ID
      );
    },
  },
  mounted() {
    this.updateCounter();
  },
  methods: {
    ...mapMutations({
      mutateNotifications: types.MUTATE_NOTIFICATIONS,
      mutateUnreadNotifications: types.MUTATE_UNREAD_NOTIFICATIONS,
    }),
    ...mapActions({
      getNotifications: types.GET_NOTIFICATIONS,
      openNotificationSidebar: types.OPEN_NOTIFICATION_SIDEBAR,
      readAllNotifications: types.READ_ALL_NOTIFICATIONS,
      getNotificationsCounter: types.GET_NOTIFICATIONS_COUNTER,
    }),
    truncateString,
    getNotificationMessage,
    handleDate(date) {
      const notificationDate = new Date(date);
      const notificationFullDate = notificationDate.toLocaleDateString(
        "pt-BR",
        { hour: "2-digit", minute: "2-digit" }
      );
      const notificationTime = notificationDate.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      if (notificationDate.toDateString() === today.toDateString()) {
        date = "Hoje às " + notificationTime;
      } else if (notificationDate.toDateString() === yesterday.toDateString()) {
        date = "Ontem às " + notificationTime;
      } else {
        date = notificationFullDate.replace(", ", " às ");
      }
      return date;
    },
    onShow() {
      this.loading = true;
      this.visible = true;
      this.currentPage = 1;
      this.updateCounter();
      this.getNotifications({ currentPage: this.currentPage })
        .then((response) => {
          this.currentPage++;
          this.mutateNotifications(response.data);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as notificações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    read(item) {
      this.reading = true;
      item.read = true;
      this.openNotificationSidebar({
        id: item.id,
        title: item.notification_type.type,
        type: item.type,
        tag: item.tag_id,
        metadata: item.metadata,
        markAsRead: true,
      });
    },
    endScroll(event) {
      if (this.visible && !this.loading && this.items.length < this.total) {
        this.loading = true;
        this.getNotifications({ currentPage: this.currentPage })
          .then((response) => {
            this.currentPage++;
            this.mutateNotifications({
              ...response.data,
              data: [...this.notifications.data, ...response.data.data],
            });
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar as notificações. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    readAll() {
      this.$swal({
        title: "Confirmação",
        text: `Deseja marcar todas as notificações como lidas?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Marcar",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      })
        .then((result) => {
          if (result.value) {
            this.readAllNotifications({})
              .then(() => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Sucesso",
                    text: "Todas as notificações foram marcadas como lida!",
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.onShow();
              })
              .catch(() => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: "Ocorreu um erro ao carregar as notificações. Entre em contato com o setor de TI.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCounter() {
      this.getNotificationsCounter().catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as notificações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      //update counter in 5 minutes
      this.timer = setTimeout(() => {
        this.updateCounter();
      }, 300000);
    },
    //prevent hidding when sidebar is opened
    onHide(bvEvent) {
      if (this.reading) {
        bvEvent.preventDefault();
      } else {
        this.visible = false;
        this.mutateNotifications([]);
      }
      this.reading = this.notificationSibebar.visible;
    },
    goToNotificationInbox() {
      this.$router.push({ name: "notification-inbox-unread" });
    },
  },
};
</script>

<style lang="scss">
.badge-purple {
  background-color: #7e2d87;
}
.badge-blue {
  background-color: #116589;
}
.notification-cart-footer {
  display: inline-flex !important;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
