import { READ_ACTION, CONSULTANT_RESOURCE } from "@/constants/resources";

export default [
  {
    header: "Menu",
    resource: CONSULTANT_RESOURCE,
    action: READ_ACTION,
  },
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    resource: CONSULTANT_RESOURCE,
    action: READ_ACTION,
  },
];
