import {
  READ_ACTION,
  CONSULTANT_RESOURCE,
  LEAD_PN,
  TRANSFER_CONSULTANT,
  MANAGE_ACTION,
  FEEDZ,
  USEFUL_LINKS,
  USEFUL_LINKS_FRANCHISE,
  SOLIDES
} from "@/constants/resources";

var navMenu = [
  {
    title: "Gestão de PNs",
    icon: "none",
    children: [
      {
        title: "Leads PNs",
        route: "potential-partners-list",
        icon: "UserCheckIcon",
        resource: LEAD_PN,
        action: READ_ACTION,
      },
      {
        title: "Minha equipe",
        route: "consultants-list",
        icon: "UsersIcon",
        resource: CONSULTANT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Transferir PN",
        route: "consultant-transfer",
        icon: "RepeatIcon",
        resource: TRANSFER_CONSULTANT,
        action: MANAGE_ACTION,
      },
      {
        title: "Feedz",
        href: "https://app.feedz.com.br/inicio",
        icon: "UserCheckIcon",
        resource: FEEDZ,
        action: READ_ACTION,
      },
      {
        title: "Sólides",
        href: "https://solides.com.br/",
        icon: "SmileIcon",
        resource: SOLIDES,
        action: READ_ACTION,
      },
      {
        title: "Links úteis",
        href: "https://drive.google.com/file/d/1B09b1thbe_ot-2F1k2ZztAagH_wz4Isr/view",
        icon: "ListIcon",
        resource: USEFUL_LINKS,
        action: READ_ACTION,
      },
      {
        title: "Links úteis franqueados",
        href: "https://drive.google.com/file/d/1nTqXUFJThergG8r8phSVPfe9rPtvN0f5/view?usp=sharing",
        icon: "BookIcon",
        resource: USEFUL_LINKS_FRANCHISE,
        action: READ_ACTION,
      }
    ].filter(Boolean),
  },
];

export default navMenu;
