var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('notification-sidebar'),_c('b-nav-item-dropdown',{staticClass:"dropdown-notification mr-25",attrs:{"menu-class":"dropdown-menu-media","right":""},on:{"shown":_vm.onShow,"hide":_vm.onHide,"hidden":function($event){_vm.loading = false}},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body",attrs:{"badge":_vm.counterTotal,"badge-classes":"bg-danger","icon":"BellIcon","size":"21"}})]},proxy:true}])},[_c('li',{staticClass:"dropdown-menu-header"},[_c('div',{staticClass:"dropdown-header d-flex"},[_c('h4',{staticClass:"notification-title mb-0 mr-auto"},[_vm._v("Notificações")]),(_vm.counterNotifications > 0)?_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_c('span',[_vm._v(" "+_vm._s(_vm.counterNotifications)+" novas ")])]):_vm._e(),_vm._v("   "),(_vm.counterPendencies > 0)?_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_c('span',[_vm._v(" "+_vm._s(_vm.counterPendencies)+" pendências ")])]):_vm._e(),_vm._v("   "),(_vm.counterSolved > 0)?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_c('span',[_vm._v(" "+_vm._s(_vm.counterSolved)+" resolvidas ")])]):_vm._e()],1)]),_c('vue-perfect-scrollbar',{staticClass:"scrollable-container media-list scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"li"},on:{"ps-y-reach-end":_vm.endScroll}},[(_vm.items.length > 0)?_vm._l((_vm.items),function(item){return _c('div',{key:item.id},[_c('b-media',[_c('b-button',{staticClass:"text-left",style:(item.read
                  ? 'padding:0; color:#6e6b7b !important'
                  : 'padding:0'),attrs:{"variant":"link"},on:{"click":function($event){$event.preventDefault();return _vm.read(item)}}},[_c('p',{staticClass:"media-heading",staticStyle:{"padding-bottom":"5px"}},[_c('span',{staticClass:"font-weight-bolder"},[_c('b-badge',{attrs:{"variant":"purple"}},[_vm._v(" "+_vm._s(item.notification_type.type)+" ")]),(item.notification_tag)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"blue"}},[_vm._v(" "+_vm._s(item.notification_tag.tag)+" ")]):_vm._e()],1)]),_c('p',{staticClass:"media-heading",staticStyle:{"padding-bottom":"5px"}},[_c('small',{staticClass:"text-muted mr-1"},[_vm._v(" "+_vm._s(_vm.handleDate(item.created_at))+" ")])]),_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.truncateString(
                    _vm.getNotificationMessage(
                      item.metadata,
                      item.type,
                      item.tag_id
                    ),
                    115
                  )
                )}})])],1)],1)}):(!_vm.loading)?[_c('span',{staticClass:"d-flex justify-content-center m-2"},[_vm._v(" Nenhuma nova notificação!")])]:_vm._e()],2),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center m-2"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('li',{staticClass:"dropdown-menu-footer"},[_c('div',{staticClass:"justify-content-center notification-cart-footer"},[(_vm.canReadAll)?_c('b-dropdown-item',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.readAll}},[_vm._v(" Marcar todas ")])],1):_vm._e(),_c('b-dropdown-item',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-success"},on:{"click":_vm.goToNotificationInbox}},[_vm._v(" Caixa de entrada ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }