<template>
  <div>
    <announcement-alert />
    <float-to-do-sidebar />
    <layout-vertical>
      <router-view />

      <app-customizer v-if="showCustomizer" slot="customizer" />
    </layout-vertical>
  </div>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import AnnouncementAlert from "@/modules/account/views/AnnouncementAlert.vue";
import { $themeConfig } from "@themeConfig";
import FloatToDoSidebar from "@/modules/todo/components/FloatToDoSidebar.vue";

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    AnnouncementAlert,
    FloatToDoSidebar,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
};
</script>
